import { useCallback } from 'react';
import Link from 'next/link';
import { cx } from 'class-variance-authority';

import { RichTextBanner as RichTextBannerType } from '@oresundsbron/api';
import { Button } from '@oresundsbron/bridge-ui';

import { useLinks } from '~/hooks';

export const RichTextBanner = ({
  heading,
  preamble,
  link,
}: RichTextBannerType) => {
  const { getLink } = useLinks();

  /* As design calls for rendering the 2nd part of the heading and button label in bolder font-weight,
   * editor can use a template pattern with {0}nnn{/0} to control the rendering of the texts
   */
  const firstPartOfHeading = useCallback(() => {
    return heading.match(/(.*?)\{0}/)?.[1] || heading;
  }, [heading]);

  /* See comment above, editor needs to be able to control to
   * render part of the value in bolder font-weight
   */
  const secondPartOfHeading = useCallback(() => {
    return heading.match(/\{0\}(.*?)(\{\/0\}|$)/)?.[1] || '';
  }, [heading]);

  return (
    <div className="flex flex-col gap-6 rounded-xl bg-blue-950 p-8 text-center text-white md:px-16">
      <h2 className="text-4xl">
        <span>{firstPartOfHeading()}</span>
        <span className="font-semibold">{secondPartOfHeading()}</span>
      </h2>

      <p className="text-md leading-7">{preamble}</p>

      <div>
        <Button
          as={Link}
          href={getLink(link.pageLink.sys.id || '')?.path!}
          className={cx([
            [
              'border-2',
              '!no-underline',
              'border-white',
              '!bg-blue-950',
              'hover:!bg-white',
              'hover:!text-black',
              'hover:border-white',

              'focus:!border-black',
              'focus:!bg-white',
              'focus:text-black',
              'focus:shadow-button-focus-inverted',
              'focus:ring-2',
              'focus:ring-white',

              'active:!border-white',
              'active:!bg-white',
              'active:!text-black',
            ],
          ])}
        >
          <span className="font-normal">{firstPartOfHeading()}</span>
          <span className="font-medium">{secondPartOfHeading()}</span>
        </Button>
      </div>
    </div>
  );
};
