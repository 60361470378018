import {
  ContentfulQueryKeys,
  useContentfulQueries,
  CategoryFamily,
  Collection,
  Category,
} from '@oresundsbron/api';
import { useRouter } from 'next/router';

export function useCategories(...families: CategoryFamily['codeId'][]) {
  const { locale, isPreview } = useRouter();

  return useContentfulQueries<Collection<Category>>(
    families.map((family) => ({
      queryKey: ContentfulQueryKeys.CategoryList({
        codeId: family,
        locale,
        preview: isPreview,
        restrictedToLocale: locale,
      }),
      queryFn: (q) =>
        q.Category.list({
          codeId: family,
          preview: isPreview,
          restrictedToLocale: locale || '',
          locale,
        }),
      staleTime: Infinity,
    }))
  );
}
