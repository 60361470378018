import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export const useClubParams = () => {
  const router = useRouter();
  const [currentPageParam, setCurrentPageParam] = useState<number>(
    parseInt(router.query.page as string)
  );
  const [currentCategoryParam, setCurrentCategoryparam] = useState<string>(
    router.query.category as string
  );
  const [currentCountryParam, setCurrentCountryparam] = useState<string>(
    router.query.country as string
  );

  const setQueryParams = (paramsArray: { key: string; value: string }[]) => {
    const { slug, ...filteredRouterQuery } = router.query;
    const mergedQuery = {
      ...filteredRouterQuery,
      ...paramsArray.reduce((newParams, { key, value }) => {
        newParams[key] = value;
        return newParams;
      }, {} as { [key: string]: string }),
    };

    router.push(
      {
        pathname: router.asPath.split('?')[0],
        query: mergedQuery,
      },
      undefined,
      { shallow: true }
    );
  };

  const removeQueryParams = (paramsToRemove: string[]) => {
    const url = new URL(router.asPath, window.location.origin);

    paramsToRemove.forEach((key) => {
      url.searchParams.delete(key);
    });

    router.push(`${url.pathname}${url.search}`, undefined, { shallow: true });
  };

  useEffect(() => {
    if (!router.isReady) return;
    setCurrentPageParam(parseInt(router.query.page as string) || 1);
    setCurrentCategoryparam(router.query.category as string);
    setCurrentCountryparam(router.query.country as string);
  }, [router, router.isReady]);

  return {
    currentPageParam,
    currentCategoryParam,
    currentCountryParam,
    setQueryParams,
    removeQueryParams,
  };
};
