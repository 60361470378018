import { ContentfulQueryKeys, useContentfulQuery } from '@oresundsbron/api';
import { useRouter } from 'next/router';

export function useLocations(locale: string) {
  const { isPreview } = useRouter();
  return useContentfulQuery(
    ContentfulQueryKeys.LocationList({
      restrictedToLocale: locale,
      preview: isPreview,
      locale,
    }),
    (q) =>
      q.Location.list({
        restrictedToLocale: locale,
        preview: isPreview,
        locale,
      }),
    { staleTime: Infinity }
  );
}
