import { FC, ReactNode } from 'react';

import { Block, Inline } from '@contentful/rich-text-types';

import { Sys } from '@oresundsbron/api';

import { ButtonLink } from './ButtonLink';
import { ClubList } from './ClubList';
import { PriceList } from './PriceList';
import { ProductCardGroup } from './ProductCardGroup';
import { RichTextBanner } from './RichTextBanner';

const EntryMap: Record<string, FC<any>> = {
  ButtonLink: ButtonLink,
  ProductCardGroup: ProductCardGroup,
  PriceList: PriceList,
  ClubList: ClubList,
  ComponentRichTextBanner: RichTextBanner,
};

/* "HACK" **
 *   using reqular function expression to fix
 *   "missing display  name" error: https://stackoverflow.com/a/52993237/9602449
 ***/
export function EmbeddedEntry(entries: { sys: Sys; __typename: string }[]) {
  return function (node: Block | Inline, children: ReactNode) {
    const entry = entries.find((e) => e.sys?.id === node.data.target.sys.id);

    if (!entry) {
      return null;
    }

    const Component = EntryMap[entry.__typename];

    return Component ? Component({ ...entry, children }) : null;
  };
}
