import {
  useContentfulQuery,
  ClubOfferListItem,
  ClubGuideListItem,
  ContentfulQueryKeys,
} from '@oresundsbron/api';
import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { useQueryClient, UseQueryOptions } from '@tanstack/react-query';

export const articlePageSize = 12;

type ArticleListItem = ClubOfferListItem | ClubGuideListItem;

export function useClubArticles(options?: UseQueryOptions) {
  const { locale, isPreview: preview, query } = useRouter();
  const client = useQueryClient();

  const { data, isFetching } = useContentfulQuery(
    ContentfulQueryKeys.ClubArticles({
      locale,
      preview,
      limit: articlePageSize,
      skip: (parseInt(query.page as string) - 1) * articlePageSize,
      category: query.category,
      location: query.country,
    }),
    (req) =>
      req.Club.list({
        locale,
        preview,
        limit: articlePageSize,
        skip: (parseInt(query.page as string) - 1) * articlePageSize,
        category: query.category as string,
        location: query.country as string,
      }),
    {
      keepPreviousData: true,
      ...(options ?? {}),
    }
  );

  const items = useMemo(() => {
    return (data?.items as ArticleListItem[]) ?? ([] as ArticleListItem[]);
  }, [data]);

  const total = useMemo(() => {
    return (
      client.getQueryData(
        ContentfulQueryKeys.ClubArticles({
          locale,
          preview,
          limit: articlePageSize,
          skip: 0,
        })
      ) ?? 0
    );
  }, [client, locale, preview]);

  const pages = useMemo(() => {
    return Math.ceil((data?.total ?? 0) / articlePageSize);
  }, [data]);

  const categoryCount = {} as Record<string, number>; // TODO: Fix calc of categories
  return {
    items,
    total,
    categoryCount,
    pages,
    isFetching,
  };
}
