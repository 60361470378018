import { FC } from 'react';
import Image from 'next/image';

type LogoProps = {
  logoSrc: string;
  alt: string;
};

export const Logo: FC<LogoProps> = ({ logoSrc, alt }) => (
  <Image
    alt={alt}
    className="my-4 sm:my-0"
    src={logoSrc}
    sizes=""
    height={44}
    width={226}
    priority
    unoptimized
  />
);
