import { NavigationLink } from '@oresundsbron/api';
import { Box, Typography } from '@oresundsbron/bridge-ui';
import { FC } from 'react';
import { Root, Item, List } from '@radix-ui/react-navigation-menu';
import { useLinks } from '../../hooks/useLinks';
import { useRouter } from 'next/router';
import { Link } from '@oresundsbron/api/src/contentful/Links';
import { DefaultLink } from '~/components/Links/DefaultLink';

export interface FooterSectionProps {
  title: string;
  link?: Link;
  links: NavigationLink[];
}

const FooterSection: FC<FooterSectionProps> = ({ title, links }) => {
  const router = useRouter();
  const { getLink } = useLinks();

  return (
    <Box className="col-span-full text-left sm:col-span-4 md:col-span-3">
      <Typography intent="title" id={title} className="mb-8 text-white">
        {title}
      </Typography>
      <Root
        orientation="vertical"
        value={router.asPath}
        defaultValue={router.asPath}
        aria-labelledby={title}
        asChild
      >
        <List>
          {links.map(({ title: label, sys, pageLink }) => {
            const link = getLink(pageLink?.sys.id);
            return (
              <Item key={sys.id} value={sys.id} className="py-1">
                <DefaultLink
                  href={`/${link?.locale}${link?.path}`}
                  navLinkWrapper
                  className="text-white hover:!text-white"
                  underline={false}
                >
                  {label}
                </DefaultLink>
              </Item>
            );
          })}
        </List>
      </Root>
    </Box>
  );
};

export default FooterSection;
