import { FC, useMemo } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Card, Typography } from '@oresundsbron/bridge-ui';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useQueryClient } from '@tanstack/react-query';
import { Globe } from '@oresundsbron/icons';

const translations = { sv: 'swedish', en: 'english', da: 'danish' };

export const LanguagePicker: FC = () => {
  const { t } = useTranslation(['common']);
  const { push, asPath, locales, locale } = useRouter();
  const queryClient = useQueryClient();

  const redirect = useMemo(
    () =>
      queryClient.getQueryData([
        'page',
        'redirects',
        {
          locale,
          slug: asPath.split('?')[0].split('#')[0].split('/').filter(Boolean), // Remove hash parameters and query parameters from URL, else language selector will not work. See devops ticket 5687
        },
      ]),
    [asPath, locale, queryClient]
  );

  const items = useMemo(() => {
    if (redirect) {
      return Object.entries(redirect).map(([k, v]) => (
        <DropdownMenu.Item
          key={k}
          className="cursor-pointer"
          onSelect={() => push(v, undefined, { locale: k })}
        >
          <Typography className="px-4 py-3 text-right">
            {t(translations[k as keyof typeof translations])}
          </Typography>
        </DropdownMenu.Item>
      ));
    } else if (
      asPath.includes('/account/') ||
      asPath.includes('/news/') ||
      asPath.includes('/club/')
    ) {
      // When you're on an individual news page (eg http://localhost:3000/sv/news/anmalan-till-broloppet-2025-oppnar-den-1-februari/3405890) we cannot
      // switch to the same news item in another languange since they are two completely separate entities.
      // SV news items are from TT, DA news items are from Ritzau. They have nothing in common. EN has no news at all.
      // Because of that, just redirect to startpage.
      // The same behaviour is needed for individual club offer pages.

      const redirectPath = asPath.includes('/account/') ? asPath : '/';

      return (locales || [])
        .filter((x) => x !== locale)
        .map((loc) => (
          <DropdownMenu.Item
            key={loc}
            className="cursor-pointer"
            onSelect={() => push(redirectPath, undefined, { locale: loc })}
          >
            <Typography className="px-4 py-3 text-right">
              {t(translations[loc as keyof typeof translations])}
            </Typography>
          </DropdownMenu.Item>
        ));
    }
  }, [redirect, t, push, locales, asPath, locale]);

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button
          aria-label={t(translations[locale as keyof typeof translations])}
          disabled={!items}
          className="flex items-center rounded-full border-2 border-transparent p-3 font-medium text-type-secondary hover:bg-neutral-50 hover:text-type-heading lg:px-6 lg:py-2"
        >
          <Globe />
          <span className="ml-2 hidden lg:inline-block">
            {t(translations[locale as keyof typeof translations])}
          </span>
        </button>
      </DropdownMenu.Trigger>
      {!!items ? (
        <DropdownMenu.Portal>
          <Card.Root
            as={DropdownMenu.Content}
            className="rounded-md bg-white p-2"
            rounded={false}
            sideOffset={5}
          >
            {items}
          </Card.Root>
        </DropdownMenu.Portal>
      ) : null}
    </DropdownMenu.Root>
  );
};
