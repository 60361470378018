import { NextWebVitalsMetric } from 'next/app';
import Router from 'next/router';
import TagManager from 'react-gtm-module';

type PurchaseItem =
  | { item_id: string; item_name?: string }
  | ({ item_id?: string; item_name: string } & {
      item_category: 'Private' | 'Business';
      item_variant:
        | 'OperatorSignup'
        | 'OperatorOnlineTicket'
        | 'OperatorMyAccount';
      price: string;
      quantity?: string;
      item_name: string;
    });

type Purchase = {
  transaction_id: string;
  value: string;
  currency: string;
  coupon?: string;
  items: PurchaseItem[];
};
type Voucher = {
  voucherName: string;
  discount: string;
};
type userData = {
  email?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  city?: string;
  state?: string;
  address1?: string;
  zipCode?: string;
  company?: string;
  country?: string;
};

const sendEvent = (event: Record<string, unknown>) =>
  TagManager.dataLayer({ dataLayer: event });

export const pageView = (url: string) =>
  sendEvent({
    event: 'PageView',
    action: url,
  });

export const webMetric = ({ name, label, value }: NextWebVitalsMetric) =>
  sendEvent({
    event: name,
    eventCategory:
      label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
    eventValue: Math.round(name === 'CLS' ? value * 1000 : value),
    eventLabel: `/${Router.locale}${Router.asPath}`,
    nonInteraction: true,
  });

export const purchase = (ecommerce: Purchase, user: userData) => {
  sendEvent({
    event: 'purchase',
    ecommerce,
    userData: user,
  });
};

export const resetPurchase = () =>
  sendEvent({
    ecommerce: null,
  });

export const sendContactForm = (method?: string) => {
  sendEvent({
    event: 'contactUs',
    pagePath: document.location.href,
    loginMethod: method ? method : '',
  });
};

export const sendCorporateForm = (subject?: string) => {
  sendEvent({
    event: 'corporateForm',
    pagePath: document.location.href,
    subject: subject,
  });
};

export const sendOpenClubVoucherModal = (voucher: Voucher) => {
  sendEvent({
    event: 'voucherDownload',
    locale: Router.locale,
    discountPercent: voucher.discount,
    voucherName: voucher.voucherName,
  });
};

export const subscribeToNewsLetterOnPurchase = (serviceType: string) => {
  sendEvent({
    event: 'newsletterSignup',
    pagePath: document.location.href.replace(location.hash, ''), // Exclude hash from url, else it will be http://www.oresundsbron.com/sv/pendlare/oresund-pendlare-business/registrera#termsOfAgreement
    serviceType: serviceType,
  });
};

export const clickReplaceBizz = (btn: 'replace' | 'edit') => {
  sendEvent({
    event: 'click_replace_bizz',
    button_text: btn,
    country: Router.locale,
  });
};

export const acceptReplaceBizz = (btn: 'yes' | 'no') => {
  sendEvent({
    event: 'locations_replace_bizz',
    button_text: btn,
    country: Router.locale,
  });
};

export const choiceReplaceBizz = (choice: 'bizz' | 'numberplate') => {
  sendEvent({
    event: 'numberplate_replace_bizz',
    choice: choice,
    country: Router.locale,
  });
};

export const confirmReplaceBizz = () => {
  sendEvent({
    event: 'confirm_adress_replace_bizz',
    country: Router.locale,
  });
};
