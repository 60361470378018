import { filter, head, map } from 'fp-ts/lib/Array';
import { map as mapO, toUndefined, getOrElse } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/function';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { Logo } from '../components/Logo';
import { useLinks } from '../hooks/useLinks';
import { useNavigation } from '../hooks/useNavigation';
import { isNavigationLink } from '../lib/navigation';
import { LanguagePicker } from './LanguagePicker';
import { MainNavigation } from './MainNavigation';
import { lookup } from 'fp-ts/lib/Record';
import { Eq } from 'fp-ts/lib/string';
import { getIsOresundPay } from '~/stores/auth';
import { match as matchB } from 'fp-ts/lib/boolean';
import { cx } from 'class-variance-authority';
import Link from 'next/link';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { User } from '@oresundsbron/icons';

export const OSBNavBar = () => {
  const { t } = useTranslation(['common'], { nsMode: 'fallback' });
  const { getLink } = useLinks();

  const nav = useNavigation('Header');
  const router = useRouter();
  const { query, pathname } = router;
  const isOresundPaySession = getIsOresundPay();
  const colorMode = useMemo(
    () => (pathname.includes('club') ? 'club' : ''),
    [pathname]
  );
  const isAccountPage = useMemo(() => pathname.includes('account'), [pathname]);

  const [logoSrc, setLogoSrc] = useState<string>('/oresundsbron-logo.svg');

  const links = useMemo(
    () =>
      pipe(
        nav?.sections || [],
        filter(isNavigationLink),
        map(({ title, pageLink }) => ({
          title,
          link: getLink(pageLink.sys.id),
        })),
        filter(({ link }) => !!link)
      ),
    [nav?.sections, getLink]
  );

  let isOresundPay = useMemo(
    () =>
      pipe(
        isOresundPaySession,
        matchB(
          () =>
            pipe(
              query,
              lookup('source'),
              mapO(
                (s) =>
                  !!Eq.equals(s as unknown as string, 'pay') && isAccountPage
              ),
              getOrElse(() => false)
            ),
          () => isAccountPage
        )
      ),
    [query, isOresundPaySession, isAccountPage]
  );

  useEffect(
    () =>
      setLogoSrc(isOresundPay ? '/ospay-logo.svg' : '/oresundsbron-logo.svg'),
    [isOresundPay]
  );

  return (
    <header
      className="text-typ border-b border-neutral-100"
      data-mode={colorMode}
    >
      <NavigationMenu.Root className="relative">
        <NavigationMenu.List className="flex w-full list-none flex-wrap pb-2 sm:flex-nowrap sm:py-6 md:px-16">
          {/* Logo */}
          <NavigationMenu.Item className="mr-auto w-40 shrink-0 self-center pl-4 md:w-56 md:pl-0">
            {pipe(
              links,
              head,
              mapO(({ title, link }) => (
                <Link
                  href={link?.path || ''}
                  aria-label={title}
                  className="order-1"
                >
                  <Logo logoSrc={logoSrc} alt="Øresundsbron logo" />
                </Link>
              )),
              toUndefined
            )}
          </NavigationMenu.Item>

          {/* Use as line break in mobile */}
          <NavigationMenu.Item className="order-5 h-0 basis-full sm:order-2 sm:h-auto sm:basis-0"></NavigationMenu.Item>

          {/* Navigation links */}
          {pipe(
            links,
            map(({ title, link }) => (
              <NavigationMenu.Item
                key={link?.path}
                className="order-6 flex w-1/3 justify-center border-t border-neutral-100 pt-2 sm:order-3 sm:w-auto sm:border-0 sm:pt-0"
              >
                <NavigationMenu.Link asChild>
                  <Link
                    href={link?.path || ''}
                    className={cx(
                      'flex justify-center rounded-full border-2 px-6 py-2 text-sm font-medium transition-all hover:bg-neutral-50 hover:text-type-heading sm:mx-2 sm:text-base md:mx-4',
                      router.asPath.startsWith(link?.path || '')
                        ? 'border-type-heading text-type-heading'
                        : 'border-transparent text-type-secondary'
                    )}
                  >
                    {title}
                  </Link>
                </NavigationMenu.Link>
              </NavigationMenu.Item>
            ))
          )}

          {/* Language picker */}
          <NavigationMenu.Item className="order-2 ml-auto self-center sm:order-6">
            <LanguagePicker />
          </NavigationMenu.Item>

          {/* My account */}
          <NavigationMenu.Item className="order-3 self-center sm:order-7">
            <NavigationMenu.Link asChild>
              <Link
                href="/account/dashboard"
                className="flex items-center rounded-full border-2 border-transparent p-3 font-medium text-type-secondary hover:bg-neutral-50 hover:text-type-heading lg:mx-4 lg:bg-primary-700 lg:px-6 lg:py-2 lg:text-white lg:hover:bg-primary-900 lg:hover:text-white"
              >
                <User aria-label={t('action.myAccount')} />

                <span className="ml-2 hidden lg:inline-block">
                  {t('action.myAccount')}
                </span>
              </Link>
            </NavigationMenu.Link>
          </NavigationMenu.Item>

          {/* Fold out menu */}
          <NavigationMenu.Item className="order-4 self-center sm:order-8">
            <MainNavigation />
          </NavigationMenu.Item>
        </NavigationMenu.List>
      </NavigationMenu.Root>
    </header>
  );
};
